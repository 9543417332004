import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import './SynergyPeople.css';
import logo from '../assets/synergy-people-logo.png';
import { Alert, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class SynergyPeople extends Component {
    static displayName = SynergyPeople.name;

    constructor(props) {
        super(props);
        document.title = "Synergy People Registration Form";
        this.state = {
            isOverlayActive: false,
            isRedirect: false,
            citizenship: 'Australian Citizen',
            howDidYouHearAboutUs: 'Google',
            errorMessage: ''
        };
        this.resumeFileInput = React.createRef();
        this.photoIDFileInput = React.createRef();
    }

    handleChanges = (event) => {
        if (this.state.isOverlayActive) return;
        let value = event.target.type === 'number' ? event.target.valueAsNumber : event.target.value;
        this.setState({
            [event.target.name]: value
        });
    }

    handleSubmit = async (event) => {
        if (this.state.isOverlayActive === true) return;
        event.preventDefault();
        this.setState({'isOverlayActive': true});

        const uploadData = new FormData();
        // uploadData.append('email', this.state.email);
        uploadData.append('resume', this.resumeFileInput.current.files[0]);
        uploadData.append('photoId', this.photoIDFileInput.current.files[0]);
        uploadData.append('data', JSON.stringify(this.state));
        // uploadData.append('data', null);
        let submitResult = await fetch('api/SynergyPeople', { method: 'POST', body: uploadData });
        if(submitResult.ok) {
            this.setState({
                'isOverlayActive': false,
                'isRedirect': true,
                'errorMessage': ''
            });
        } else{
            this.setState({'errorMessage': 'Error'})
        }

    }
    handleClose() {
        this.setState({'errorMessage': ''});
      };

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/success/whs' />
        }

        return (
            <LoadingOverlay active={this.state.isOverlayActive} spinner>
                <Container>
                {this.state.errorMessage !== '' && <Snackbar open={true} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert severity="error" variant="filled"  action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={this.handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }> Error occured please contact <a style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'white' }} href="mailto:support@recruitwizard.com">Support</a>.</Alert>
            </Snackbar>}
                    <div className="row">
                        <img src={logo} width="auto" height="auto" alt="logo" />
                        <form onSubmit={this.handleSubmit}>
                            <div> {/*Row*/}
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>First name <span className="required-symbol">*</span></label></div>
                                    <div className="input-container"><input type="text" name="firstName" required onChange={this.handleChanges} /></div>
                                </div>
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>Last name <span className="required-symbol">*</span></label></div>
                                    <div className="input-container"><input type="text" name="lastName" required onChange={this.handleChanges} /></div>
                                </div>
                            </div>
                            <div> {/*Row*/}
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>Phone <span className="required-symbol">*</span></label></div>
                                    <div className="input-container"><input type="text" name="phone" required onChange={this.handleChanges} /></div>
                                </div>
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>Email <span className="required-symbol">*</span></label></div>
                                    <div className="input-container"><input type="email" name="email" required onChange={this.handleChanges} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" /></div>
                                </div>
                            </div>
                            <div> {/*Row*/}
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>Suburb</label></div>
                                    <div className="input-container"><input type="text" name="suburb" onChange={this.handleChanges} /></div>
                                </div>
                                <div className="half-column"> {/*Column*/}
                                    <div className="form-title-container"><label>Date Of Birth</label></div>
                                    <div className="input-container"><input type="date" name="birthdate" onChange={this.handleChanges} /></div>
                                </div>
                            </div>
                            <div> {/*Row*/}
                                <div>
                                    <div className="form-title-container"><label>How far are you willing to travel? <span className="required-symbol">*</span></label></div>
                                    <div className="input-container">
                                        <div><label><input type="radio" value="KM" name="travelUnits" required onChange={this.handleChanges} />KM</label></div>
                                        <div><label><input type="radio" value="Minutes" name="travelUnits" onChange={this.handleChanges} />Minutes</label></div>
                                        <div className="input-container"><input type="number" name="travelValue" required onChange={this.handleChanges} /></div>
                                    </div>
                                </div>
                            </div>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>Citizenship <span className="required-symbol">*</span></label></div>
                                <div className="input-container">
                                    <select name="citizenship" required onChange={this.handleChanges}>
                                        <option value="Australian Citizen">Australian Citizen</option>
                                        <option value="Permanent Resident">Permanent Resident</option>
                                        <option value="Working Visa">Working Visa</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.citizenship === "Other" &&
                                <div> {/*Row*/}
                                    <div className="form-title-container"><label>If other, please specify: <span className="required-symbol">*</span></label></div>
                                    <div className="input-container"><textarea name="citizenshipOther" required={this.state.citizenship === 'Other'} onChange={this.handleChanges}></textarea></div>
                                </div>
                            }
                            <div> {/*Row*/}
                                <div>
                                    <div className="form-title-container"><label>Are you willing to obtain a Police Check? <span className="required-symbol">*</span></label></div>
                                    <div className="input-container">
                                        <div><label><input type="radio" value="Yes" name="isWillingPoliceCheck" onChange={this.handleChanges} required />Yes</label></div>
                                        <div><label><input type="radio" value="No" name="isWillingPoliceCheck" onChange={this.handleChanges} />No</label></div>
                                    </div>
                                </div>
                            </div>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>What roles/types of work are you interested in? <span className="required-symbol">*</span></label></div>
                                <div className="input-container"><textarea name="rolesTypesOfWorkPreference" required onChange={this.handleChanges}></textarea></div>
                            </div>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>What is your desired hourly rate (Exc. Super)? <span className="required-symbol">*</span></label></div>
                                <div className="input-container"><input type="number" name="desiredHourlyRate" required onChange={this.handleChanges} /></div>
                            </div>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>What is your desired salary (Exc. Super)? <span className="required-symbol">*</span></label></div>
                                <div className="input-container"><input type="number" name="desiredSalary" required onChange={this.handleChanges} /></div>
                            </div>
                            <hr></hr>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>Resume <span className="required-symbol">*</span></label></div>
                                <div><p>Please provide an updated resume</p></div>
                                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                                <div><input type="file" name="resumeFile" id="resumeFile" className="input-container" required ref={this.resumeFileInput} onChange={this.handleChanges} /></div>
                            </div>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>Photo ID <span className="required-symbol">*</span></label></div>
                                <div><p>Please provide a copy of one of these types of photo ID - Driver's licence or Passport</p></div>
                                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                                <div><input type="file" name="photoIdFile" id="photoIdFile" className="input-container" required ref={this.photoIDFileInput} onChange={this.handleChanges} /></div>
                            </div>
                            <hr></hr>
                            <div> {/*Row*/}
                                <div className="form-title-container"><label>How did you hear about us? <span className="required-symbol">*</span></label></div>
                                <div className="input-container">
                                    <select name="howDidYouHearAboutUs" required onChange={this.handleChanges}>
                                        <option value="Google">Google</option>
                                        <option value="Word of Mouth">Word of Mouth</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Seek">Seek</option>
                                        <option value="Indeed">Indeed</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.howDidYouHearAboutUs === "Other" &&
                                <div> {/*Row*/}
                                    <div className="form-title-container"><label>Please specify</label></div>
                                    <div className="input-container"><input type="text" name="howDidYouHearAboutUsOther" required={this.state.howDidYouHearAboutUs === 'Other'} onChange={this.handleChanges} /></div>
                                </div>
                            }
                            <div><input type="submit" value="Submit"></input></div>
                        </form>
                    </div>
                </Container>
            </LoadingOverlay>
        )
    }
}