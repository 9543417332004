import React, { useCallback, useEffect, useMemo, useState } from "react";
import './PAYG.css';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const bgImgId = 'trc-payg-bg';
const baseWidth = 1903;

const overviewHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS01%20Overview%20of%20Policy.pdf';
const discriminationHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS02%20Anti%20Discrimination%20Policy.pdf';
const bullyingHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS06%20Anti%20Bullying%20and%20Violence%20Policy.pdf';
const drugsAlcoholHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS03%20Drugs%20and%20Alcohol%20Policy.pdf';
const compensationHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS07%20Workers%20Compensation%20and%20Injury%20Management%20Policy.pdf';
const incidentHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS05%20Incident%20Reporting%20Form.pdf';
const fairworkHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/Fairwork%20Information%20Statement.pdf';
const homeHref = 'https://cdn.recruitwizard.com/customer_data/TrcGroup/TRC/WHS09%20Work%20From%20Home%20Policy.pdf';

export default function TRC_PAYG () {
    const [isLoading, setIsLoading] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fullWidth, setFullWidth] = useState(baseWidth);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [kinName, setKinName] = useState('');
    const [kinNumber, setKinNumber] = useState('');
    const [dobType, setDobType] = useState(undefined);
    const [checkedOverview, setCheckedOverview] = useState(false);
    const [openedOverview, setOpenedOverview] = useState(false);
    const [checkedDiscrimination, setCheckedDiscrimination] = useState(false);
    const [openedDiscrimination, setOpenedDiscrimination] = useState(false);
    const [checkedBullying, setCheckedBullying] = useState(false);
    const [openedBullying, setOpenedBullying] = useState(false);
    const [checkedDrugsAlcohol, setCheckedDrugsAlcohol] = useState(false);
    const [openedDrugsAlcohol, setOpenedDrugsAlcohol] = useState(false);
    const [checkedCompensation, setCheckedCompensation] = useState(false);
    const [openedCompensation, setOpenedCompensation] = useState(false);
    const [checkedIncident, setCheckedIncident] = useState(false);
    const [openedIncident, setOpenedIncident] = useState(false);
    const [checkedFairwork, setCheckedFairwork] = useState(false);
    const [openedFairwork, setOpenedFairwork] = useState(false);
    const [checkedHome, setCheckedHome] = useState(false);
    const [openedHome, setOpenedHome] = useState(false);
    const [photoFile, setPhotoFile] = useState(null);
    const [proofFile, setProofFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const params = useParams();

    const token = useMemo(() => {
        const t = params.token ?? '';
        return t;
    }, [params]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await fetch(`api/TRCGroup/${token}`, { method: 'GET' });
            if (res.ok) {
                let data = await res.json();
                if (data && data.name) setName(data.name); // document.getElementById('name-input').value = data.name;
            }
            setIsLoading(false);
        };
        Boolean(token) && getData();
    }, [token]);

    const getFullWidth = useCallback(() => {
        const img = document.getElementById(bgImgId);
        if (img) setFullWidth(img.width);
    }, []);

    useEffect(() => getFullWidth(), [getFullWidth]);

    useEffect(() => {
        window.addEventListener('resize', getFullWidth);
        return () => window.removeEventListener('resize', getFullWidth);
      }, [getFullWidth]);

    const scale = useMemo(() => {
        if (baseWidth === fullWidth) return 1;
        return fullWidth / baseWidth;
    }, [fullWidth]);

    const canSubmit = useMemo(() => {
        return Boolean(name) && Boolean(address) && Boolean(dateOfBirth) && Boolean(kinName) && Boolean(kinNumber) && checkedBullying && checkedCompensation && checkedDiscrimination && checkedDrugsAlcohol && checkedFairwork && checkedHome && checkedIncident && checkedOverview && Boolean(photoFile) && Boolean(proofFile);
    }, [address, checkedBullying, checkedCompensation, checkedDiscrimination, checkedDrugsAlcohol, checkedFairwork, checkedHome, checkedIncident, checkedOverview, dateOfBirth, kinName, kinNumber, name, photoFile, proofFile]);

    const submitHandler = useCallback(async (event) => {
        event.preventDefault();
        if (!canSubmit) {
            setShowValidation(true);
            return;
        }
        
        const data = { 
            name: name,
            address: address,
            dateOfBirth: dateOfBirth,
            kinName: kinName,
            kinNumber: kinNumber
        };

        const uploadData = new FormData();
        uploadData.append('data', JSON.stringify(data));
        if (photoFile) uploadData.append('photo', photoFile);
        if (proofFile) uploadData.append('proof', proofFile);

        try {
            setIsLoading(true);
            const res = await fetch(`api/TheRecruitmentCompany/PAYG/${token}`, { method: 'POST', body: uploadData });
            if (res.ok) {
                let url = await res.json();
                window.location.href = url;
            }
            if (!res.ok)  {
                setErrorMessage(res.statusText);
            }
            setIsLoading(false);
            
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, [address, canSubmit, dateOfBirth, kinName, kinNumber, name, photoFile, proofFile, token]);

    const onPhotoFileChange = useCallback((e) => {
        const f = e.target.files[0];
        if (f) setPhotoFile(f);
        else setPhotoFile(null);
    }, []);

    const onProofFileChange = useCallback((e) => {
        const f = e.target.files[0];
        if (f) setProofFile(f);
        else setProofFile(null);
    }, []);

    const handleClose = () => {   
        setErrorMessage('');
      };
    return (
        <LoadingOverlay active={isLoading} spinner>
            {showValidation && !canSubmit && 
                <div
                    style={{
                        backgroundColor: '#cc1c22',
                        position: 'fixed',
                        margin: 'auto',
                        top: '20px',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 9,
                        padding: '5px 10px',
                        color: 'white'
                    }}
                >
                    Please make sure to fill all the fields, read + check all OH&S policies and attach the required documents.
                </div>
            }
            <div>
                {errorMessage !== '' && <Snackbar open={true} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert severity="error" variant="filled"  action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>Error occured please contact - The Recruitment Company</Alert>
            </Snackbar>}
            
                <div style={{ position: 'absolute', width: '100%', transformOrigin: 'top', transform: `scale(${scale})` }}>
                    <form
                        onSubmit={submitHandler}
                        style={{
                            // margin: 0,
                            margin: 'auto',
                            marginTop: '60px',
                            padding: '40px 24px',
                            paddingBottom: '10px',
                            backgroundColor: 'white',
                            width: '565px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <div className="trc-input-wrapper">
                            {Boolean(name) && <span className="trc-placeholder">Name</span>}
                            <input className="trc" placeholder="Name" value={name} onChange={({target}) => setName(target.value)} id="name-input" />
                        </div>
                        <div className="trc-input-wrapper">
                            {Boolean(address) && <span className="trc-placeholder">Address</span>}
                            <input className="trc" placeholder="Address" value={address} onChange={({target}) => setAddress(target.value)} id="address-input" style={{ height: '65px', width: '100%' }} />
                            <div style={{ fontSize: '14px' }}>NOTE: You must notify us if you change address during the course of the contract.</div>
                        </div>
                        <div className="trc-input-wrapper">
                            {(Boolean(dobType) || Boolean(dateOfBirth)) && <span className="trc-placeholder">Date of Birth</span>}
                            <input className="trc" placeholder="Date of Birth" type={dobType} value={dateOfBirth} onChange={({target}) => setDateOfBirth(target.value)} onFocus={() => setDobType('date')} id="dob-input" />
                        </div>
                        <div className="trc-input-wrapper">
                            {Boolean(kinName) && <span className="trc-placeholder">Next of Kin Name</span>}
                            <input className="trc" placeholder="Next of Kin Name" value={kinName} onChange={({target}) => setKinName(target.value)} id="kin-name-input" />
                        </div>
                        <div className="trc-input-wrapper">
                            {Boolean(kinNumber) && <span className="trc-placeholder">Next of Kin Number</span>}
                            <input className="trc" placeholder="Next of Kin Number" value={kinNumber} onChange={({target}) => setKinNumber(target.value)} id="kin-number-input" />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }} >
                            <div className="trc-red-title">OH&S policies</div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="overview" name="overview" disabled={!openedOverview} value={checkedOverview} onChange={() => setCheckedOverview(prev => !prev)} />
                                <a className="trc-chk" href={overviewHref} target="_blank" onClick={() => setOpenedOverview(true)} rel="noopener noreferrer">Overview of our WHS policy</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="discrimination" name="discrimination" disabled={!openedDiscrimination} value={checkedDiscrimination} onChange={() => setCheckedDiscrimination(prev => !prev)} />
                                <a className="trc-chk" href={discriminationHref} target="_blank" onClick={() => setOpenedDiscrimination(true)} rel="noopener noreferrer">Anti discrimination policy</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="bullying" name="bullying" disabled={!openedBullying} value={checkedBullying} onChange={() => setCheckedBullying(prev => !prev)} />
                                <a className="trc-chk" href={bullyingHref} target="_blank" onClick={() => setOpenedBullying(true)} rel="noopener noreferrer">Anti bullying policy</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="drugsalcohol" name="drugsalcohol" disabled={!openedDrugsAlcohol} value={checkedDrugsAlcohol} onChange={() => setCheckedDrugsAlcohol(prev => !prev)} />
                                <a className="trc-chk" href={drugsAlcoholHref} target="_blank" onClick={() => setOpenedDrugsAlcohol(true)} rel="noopener noreferrer">Drugs and alcohol policy</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="compensation" name="compensation" disabled={!openedCompensation} value={checkedCompensation} onChange={() => setCheckedCompensation(prev => !prev)} />
                                <a className="trc-chk" href={compensationHref} target="_blank" onClick={() => setOpenedCompensation(true)} rel="noopener noreferrer">Workers compensation policy</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="incident" name="incident" disabled={!openedIncident} value={checkedIncident} onChange={() => setCheckedIncident(prev => !prev)} />
                                <a className="trc-chk" href={incidentHref} target="_blank" onClick={() => setOpenedIncident(true)} rel="noopener noreferrer">Incident reporting process</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="fairwork" name="fairwork" disabled={!openedFairwork} value={checkedFairwork} onChange={() => setCheckedFairwork(prev => !prev)} />
                                <a className="trc-chk" href={fairworkHref} target="_blank" onClick={() => setOpenedFairwork(true)} rel="noopener noreferrer">Fairwork statement</a>
                            </div>
                            <div className="trc-checkbox-container">
                                <input className="trc" type="checkbox" id="home" name="home" disabled={!openedHome} value={checkedHome} onChange={() => setCheckedHome(prev => !prev)} />
                                <a className="trc-chk" href={homeHref} target="_blank" onClick={() => setOpenedHome(true)} rel="noopener noreferrer">Working from home policy</a>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
                            <div className="trc-red-title">Please attach</div>
                            <div className="trc-file-container">
                                <label className="trc-file" htmlFor="photo-file-input" >Photo ID</label>
                                <input type="file" id="photo-file-input" onChange={onPhotoFileChange} />
                                <label className="trc-file" htmlFor="photo-file-input" style={{ fontSize: '16px' }}>Examples: Drivers licence, passport, other photo ID</label>
                            </div>
                            <div className="trc-file-container">
                                <label className="trc-file" htmlFor="proof-file-input" >Proof of working eligibility</label>
                                <input type="file" id="proof-file-input" onChange={onProofFileChange} />
                                <label className="trc-file" htmlFor="proof-file-input" style={{ fontSize: '16px' }}>Examples: Passport, birth certificate, visa</label>
                            </div>
                        </div>
                        <p style={{ color: '#cc1c22', fontSize: '23px', lineHeight: 1.1 }}>
                            I have read and understood all documents included in this induction form and agree to adhere to the guidelines and polices included.
                        </p>
                        <input type="submit" className="trc sign-here" value="Submit & Sign" style={{ height: '82px' }} />
                        <p style={{ fontSize: '23px', lineHeight: 1.1 }}>
                            If you have any health and safety concerns you can access documents and resources on our website or contact us direct on <a className="trc" href="mailto:hello@therecruitmentcompany.com">hello@therecruitmentcompany.com</a>
                        </p>
                    </form>
                </div>
                <img
                    id={bgImgId}
                    src={`${process.env.PUBLIC_URL}/TRC/PAYG.jpg`}
                    alt="general-info"
                    style={{
                        margin: 0,
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            </div>
        </LoadingOverlay>
    );
}