import React, { Component } from 'react';
import './OUU.css';
import logo from '../assets/ouu-logo.png';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Alert, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class OUU extends Component {
  static displayName = OUU.name;

  constructor(props) {
    super(props);
    document.title = "Operation Unify Us Registration Form";
    this.state = {
      isOverlayActive: false,
      isRedirect: false,
      errorMessage: ''
    };
    this.resumeFileInput = React.createRef();
    this.photoIDFileInput = React.createRef();
  }

  handleChanges = (event) => {
    if (this.state.isOverlayActive === true) return;
    let value = event.target.type === 'number' ? event.target.valueAsNumber : event.target.value;
    if (event.target.type === 'file') {
      if (event.target.id === 'resumeFile') {
        if (this.resumeFileInput.current.files[0])
          value = this.resumeFileInput.current.files[0].name;
        else
          value = null;
      }
    }
    this.setState({
      [event.target.name]: value
    });
  }

  handleSubmit = async (event) => {
    if (this.state.isOverlayActive === true) return;
    event.preventDefault();
    this.setState({ 'isOverlayActive': true });

    try {

      if (this.resumeFileInput.current.files[0]) {
        const uploadData = new FormData();
        uploadData.append('email', this.state.email);
        uploadData.append('resume', this.resumeFileInput.current.files[0]);
        let resume = [this.resumeFileInput.current.files[0]];
        uploadData.append('resume', resume);

        let resumeResponse = await fetch('api/ouu/uploadResume', { method: 'POST', body: uploadData });
        if (!resumeResponse.ok) {
            this.setState({'errorMessage': 'error'});
        }
        console.log('RESUME UPLOADED');
      }      

      await fetch('api/ouu', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state) });
      console.log('DATA SUBMITTED');

      this.setState({ 'isOverlayActive': false });
      this.setState({ 'isRedirect': true });

    } catch (error) {
      console.log(error);
      //DISPLAY ERROR
      this.setState({ 'isOverlayActive': false });
    }
  }

  async componentDidMount() {
    if (!this.state['udfValues']) {
      let valuesRequest = await fetch('api/ouu/getPredefinedFields');
      if(valuesRequest.ok)
        this.setState({'udfValues': await valuesRequest.json()});
      else
        console.log(valuesRequest);
    }
  }

  handleClose() {   
    this.setState({'errorMessage': ''});
  };
  

  render() {
    if (this.state.isRedirect) {
      return <Redirect to='/success/ouu' />
    }
    return (
      <LoadingOverlay active={this.state.isOverlayActive} spinner>
        <Container>
        {this.state.errorMessage !== '' && <Snackbar open={true} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert severity="error" variant="filled"  action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={this.handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }> Error occured please contact <a style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'white' }} href="mailto:support@recruitwizard.com">Support</a>.</Alert>
            </Snackbar>}
          <div>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <img src={logo} width="auto" height="auto" alt="logo" />
                </div>
                <div className="col-md-12">
                  <h1>Candidate Registration Form</h1>
                </div>
                <div className="col-md-12">
                  <h4>
                    <br />
                    Personal Information
                  </h4>
                  <hr />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstname">
                      <span className="mandatory">*</span>&nbsp;First Name</label>
                    <input required type="text" name="firstname" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastname"><span className="mandatory">*</span>&nbsp;Last Name</label>
                    <input required type="text" name="lastname" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="mobile"><span className="mandatory">*</span>&nbsp;Mobile</label>
                    <input required type="text" name="mobile" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="email"><span className="mandatory">*</span>&nbsp;Email</label>
                    <input required type="text" name="email" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="dayofbirth"><span className="mandatory">*</span>&nbsp;Date Of Birth</label>
                    <input required type="date" name="birthdate" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="address"><span className="mandatory">*</span>&nbsp;Address</label>
                    <input required name="address" type="text" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="suburb"><span className="mandatory">*</span>&nbsp;Suburb</label>
                    <input required name="suburb" type="text" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="state"><span className="mandatory">*</span>&nbsp;State</label>
                    <input required name="state" type="text" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="postcode"><span className="mandatory">*</span>&nbsp;Post Code</label>
                    <input required name="postcode" type="text" className="form-control" onChange={this.handleChanges} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="country"><span className="mandatory">*</span>&nbsp;Country</label>
                    <select required name="country" onChange={this.handleChanges}>
                      <option value="">-</option>
                      <option value='AF'>Afghanistan</option>
                      <option value='AL'>Albania</option>
                      <option value='DZ'>Algeria</option>
                      <option value='AS'>American Samoa</option>
                      <option value='AD'>Andorra</option>
                      <option value='AO'>Angola</option>
                      <option value='AI'>Anguilla</option>
                      <option value='AQ'>Antarctica</option>
                      <option value='AG'>Antigua and Barbuda</option>
                      <option value='AR'>Argentina</option>
                      <option value='AM'>Armenia</option>
                      <option value='AW'>Aruba</option>
                      <option value='AU'>Australia</option>
                      <option value='AT'>Austria</option>
                      <option value='AZ'>Azerbaijan</option>
                      <option value='BS'>Bahamas</option>
                      <option value='BH'>Bahrain</option>
                      <option value='BD'>Bangladesh</option>
                      <option value='BB'>Barbados</option>
                      <option value='BY'>Belarus</option>
                      <option value='BE'>Belgium</option>
                      <option value='BZ'>Belize</option>
                      <option value='BJ'>Benin</option>
                      <option value='BM'>Bermuda</option>
                      <option value='BT'>Bhutan</option>
                      <option value='BO'>Bolivia</option>
                      <option value='BA'>Bosnia and Herzegovina</option>
                      <option value='BW'>Botswana</option>
                      <option value='BV'>Bouvet Island</option>
                      <option value='BR'>Brazil</option>
                      <option value='IO'>British Indian Ocean Territory</option>
                      <option value='BN'>Brunei</option>
                      <option value='BG'>Bulgaria</option>
                      <option value='BF'>Burkina Faso</option>
                      <option value='BI'>Burundi</option>
                      <option value='KH'>Cambodia</option>
                      <option value='CM'>Cameroon</option>
                      <option value='CA'>Canada</option>
                      <option value='CV'>Cape Verde</option>
                      <option value='KY'>Cayman Islands</option>
                      <option value='CF'>Central African Republic</option>
                      <option value='TD'>Chad</option>
                      <option value='CL'>Chile</option>
                      <option value='CN'>China</option>
                      <option value='CX'>Christmas Island</option>
                      <option value='CC'>Cocos (Keeling) Islands</option>
                      <option value='CO'>Colombia</option>
                      <option value='KM'>Comoros</option>
                      <option value='CG'>Congo</option>
                      <option value='CK'>Cook Islands</option>
                      <option value='CR'>Costa Rica</option>
                      <option value='HR'>Croatia</option>
                      <option value='CU'>Cuba</option>
                      <option value='CY'>Cyprus</option>
                      <option value='CZ'>Czech Republic</option>
                      <option value='DK'>Denmark</option>
                      <option value='DJ'>Djibouti</option>
                      <option value='DM'>Dominica</option>
                      <option value='DO'>Dominican Republic</option>
                      <option value='TP'>East Timor</option>
                      <option value='EC'>Ecuador</option>
                      <option value='EG'>Egypt</option>
                      <option value='SV'>El Salvador</option>
                      <option value='GQ'>Equatorial Guinea</option>
                      <option value='ER'>Eritrea</option>
                      <option value='EE'>Estonia</option>
                      <option value='ET'>Ethiopia</option>
                      <option value='FK'>Falkland Islands</option>
                      <option value='FO'>Faroe Islands</option>
                      <option value='FJ'>Fiji Islands</option>
                      <option value='FI'>Finland</option>
                      <option value='FR'>France</option>
                      <option value='GF'>French Guiana</option>
                      <option value='PF'>French Polynesia</option>
                      <option value='TF'>French Southern territories</option>
                      <option value='GA'>Gabon</option>
                      <option value='GM'>Gambia</option>
                      <option value='GE'>Georgia</option>
                      <option value='DE'>Germany</option>
                      <option value='GH'>Ghana</option>
                      <option value='GI'>Gibraltar</option>
                      <option value='GR'>Greece</option>
                      <option value='GL'>Greenland</option>
                      <option value='GD'>Grenada</option>
                      <option value='GP'>Guadeloupe</option>
                      <option value='GU'>Guam</option>
                      <option value='GT'>Guatemala</option>
                      <option value='GN'>Guinea</option>
                      <option value='GW'>Guinea-Bissau</option>
                      <option value='GY'>Guyana</option>
                      <option value='HT'>Haiti</option>
                      <option value='HM'>Heard Island and McDonald Islands</option>
                      <option value='VA'>Holy See (Vatican City State)</option>
                      <option value='HN'>Honduras</option>
                      <option value='HK'>Hong Kong</option>
                      <option value='HU'>Hungary</option>
                      <option value='IS'>Iceland</option>
                      <option value='IN'>India</option>
                      <option value='ID'>Indonesia</option>
                      <option value='IR'>Iran</option>
                      <option value='IQ'>Iraq</option>
                      <option value='IE'>Ireland</option>
                      <option value='IL'>Israel</option>
                      <option value='IT'>Italy</option>
                      <option value='CI'>Ivory Coast</option>
                      <option value='JM'>Jamaica</option>
                      <option value='JP'>Japan</option>
                      <option value='JO'>Jordan</option>
                      <option value='KE'>Kenya</option>
                      <option value='KI'>Kiribati</option>
                      <option value='KW'>Kuwait</option>
                      <option value='KG'>Kyrgyzstan</option>
                      <option value='LA'>Laos</option>
                      <option value='LV'>Latvia</option>
                      <option value='LB'>Lebanon</option>
                      <option value='LS'>Lesotho</option>
                      <option value='LR'>Liberia</option>
                      <option value='LY'>Libyan Arab Jamahiriya</option>
                      <option value='LI'>Liechtenstein</option>
                      <option value='LT'>Lithuania</option>
                      <option value='LU'>Luxembourg</option>
                      <option value='MO'>Macao</option>
                      <option value='MK'>Macedonia</option>
                      <option value='MG'>Madagascar</option>
                      <option value='MW'>Malawi</option>
                      <option value='MY'>Malaysia</option>
                      <option value='MV'>Maldives</option>
                      <option value='ML'>Mali</option>
                      <option value='MT'>Malta</option>
                      <option value='MH'>Marshall Islands</option>
                      <option value='MQ'>Martinique</option>
                      <option value='MR'>Mauritania</option>
                      <option value='MU'>Mauritius</option>
                      <option value='YT'>Mayotte</option>
                      <option value='MX'>Mexico</option>
                      <option value='MD'>Moldova</option>
                      <option value='MC'>Monaco</option>
                      <option value='MN'>Mongolia</option>
                      <option value='MS'>Montserrat</option>
                      <option value='MA'>Morocco</option>
                      <option value='MZ'>Mozambique</option>
                      <option value='MM'>Myanmar</option>
                      <option value='NA'>Namibia</option>
                      <option value='NR'>Nauru</option>
                      <option value='NP'>Nepal</option>
                      <option value='NL'>Netherlands</option>
                      <option value='AN'>Netherlands Antilles</option>
                      <option value='NC'>New Caledonia</option>
                      <option value='NZ'>New Zealand</option>
                      <option value='NI'>Nicaragua</option>
                      <option value='NE'>Niger</option>
                      <option value='NG'>Nigeria</option>
                      <option value='NU'>Niue</option>
                      <option value='NF'>Norfolk Island</option>
                      <option value='KP'>North Korea</option>
                      <option value='MP'>Northern Mariana Islands</option>
                      <option value='NO'>Norway</option>
                      <option value='OM'>Oman</option>
                      <option value='PK'>Pakistan</option>
                      <option value='PW'>Palau</option>
                      <option value='PS'>Palestine</option>
                      <option value='PA'>Panama</option>
                      <option value='PG'>Papua New Guinea</option>
                      <option value='PY'>Paraguay</option>
                      <option value='PE'>Peru</option>
                      <option value='PH'>Philippines</option>
                      <option value='PN'>Pitcairn</option>
                      <option value='PL'>Poland</option>
                      <option value='PT'>Portugal</option>
                      <option value='PR'>Puerto Rico</option>
                      <option value='QA'>Qatar</option>
                      <option value='RE'>Reunion</option>
                      <option value='RO'>Romania</option>
                      <option value='RU'>Russian Federation</option>
                      <option value='RW'>Rwanda</option>
                      <option value='SH'>Saint Helena</option>
                      <option value='KN'>Saint Kitts and Nevis</option>
                      <option value='LC'>Saint Lucia</option>
                      <option value='PM'>Saint Pierre and Miquelon</option>
                      <option value='VC'>Saint Vincent and the Grenadines</option>
                      <option value='WS'>Samoa</option>
                      <option value='SM'>San Marino</option>
                      <option value='ST'>Sao Tome and Principe</option>
                      <option value='SA'>Saudi Arabia</option>
                      <option value='SN'>Senegal</option>
                      <option value='SC'>Seychelles</option>
                      <option value='SL'>Sierra Leone</option>
                      <option value='SG'>Singapore</option>
                      <option value='SK'>Slovakia</option>
                      <option value='SI'>Slovenia</option>
                      <option value='SB'>Solomon Islands</option>
                      <option value='SO'>Somalia</option>
                      <option value='ZA'>South Africa</option>
                      <option value='GS'>South Georgia and the South Sandwich Islands</option>
                      <option value='KR'>South Korea</option>
                      <option value='ES'>Spain</option>
                      <option value='SD'>Sudan</option>
                      <option value='SR'>Suriname</option>
                      <option value='SJ'>Svalbard and Jan Mayen</option>
                      <option value='SZ'>Swaziland</option>
                      <option value='SE'>Sweden</option>
                      <option value='CH'>Switzerland</option>
                      <option value='SY'>Syria</option>
                      <option value='TJ'>Tajikistan</option>
                      <option value='TZ'>Tanzania</option>
                      <option value='TH'>Thailand</option>
                      <option value='TG'>Togo</option>
                      <option value='TK'>Tokelau</option>
                      <option value='TO'>Tonga</option>
                      <option value='TT'>Trinidad and Tobago</option>
                      <option value='TN'>Tunisia</option>
                      <option value='TR'>Turkey</option>
                      <option value='TM'>Turkmenistan</option>
                      <option value='TC'>Turks and Caicos Islands</option>
                      <option value='TV'>Tuvalu</option>
                      <option value='UG'>Uganda</option>
                      <option value='UA'>Ukraine</option>
                      <option value='AE'>United Arab Emirates</option>
                      <option value='GB'>United Kingdom</option>
                      <option value='US'>United States</option>
                      <option value='UM'>United States Minor Outlying Islands</option>
                      <option value='UY'>Uruguay</option>
                      <option value='UZ'>Uzbekistan</option>
                      <option value='VU'>Vanuatu</option>
                      <option value='VE'>Venezuela</option>
                      <option value='VN'>Vietnam</option>
                      <option value='WF'>Wallis and Futuna</option>
                      <option value='EH'>Western Sahara</option>
                      <option value='YE'>Yemen</option>
                      <option value='YU'>Yugoslavia</option>
                      <option value='ZM'>Zambia</option>
                      <option value='ZW'>Zimbabwe</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <h4>
                    <br />
                    Diversity
                  </h4>
                  <hr />
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ddlIsDefenceVeteran"><span className="mandatory">*</span>&nbsp;Are You A Defence Veteran?</label>
                    <select required name="ddlIsDefenceVeteran" id="ddlIsDefenceVeteran" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isDefenceVeteran'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="ddlIsInAdf">&nbsp;Are you currently serving in the ADF</label>
                    <select name="ddlIsInAdf" id="ddlIsInAdf" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isInAdf'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="ddlService">&nbsp;If so, in which service?</label>
                    <select name="ddlService" id="ddlService" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['service'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="ddlIsFirstNationsPerson"><span className="mandatory">*</span>&nbsp;Are you a first nation's person?</label>
                    <select required name="ddlIsFirstNationsPerson" id="ddlIsFirstNationsPerson" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isFirstNationPerson'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="ddlMob">&nbsp;If so, who is your MOB?</label>
                    <select name="ddlMob" id="ddlMob" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['mob'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ddlGender">&nbsp;What is your gender?</label>
                    <select name="ddlGender" id="ddlGender" onChange={this.handleChanges}>
                      <option value="">-</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                      <option value="Prefer Not To Answer">Prefer Not To Answer</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <h4>
                    <br />
                    Employment
                  </h4>
                  <hr />
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ddlIsEmployed">&nbsp;Are you currently employed?</label>
                    <select name="ddlIsEmployed" id="ddlIsEmployed" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isEmployed'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ddlIsWantWorkOverseas">&nbsp;Are you willing to work overseas?</label>
                    <select name="ddlIsWantWorkOverseas" id="ddlIsWantWorkOverseas" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isWantWorkOverseas'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ddlIsHaveSecurityClearance">&nbsp;Do you currently have a security clearance?</label>
                    <select name="ddlIsHaveSecurityClearance" id="ddlIsHaveSecurityClearance" onChange={this.handleChanges}>
                      <option value="">-</option>
                      {this.state.udfValues &&
                        this.state.udfValues['isHaveSecurityClearance'].map(item => (
                          <option key={item.id} value={item.udfValue}>{item.udfValue}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="resumeFile">&nbsp;Updated resume</label>
                    <div><input type="file" name="resumeFile" id="resumeFile" className="input-container" ref={this.resumeFileInput} onChange={this.handleChanges} /></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input required type="checkbox" name="chk-confirmation" id="chk-confirmation" className="custom-control-input" />
                      <label htmlFor="chk-confirmation" className="custom-control-label"><span className="mandatory">*</span>&nbsp;I CERTIFY THAT ALL THE INFORMATION I HAVE PROVIDED IS TRUE, COMPLETE AND CORRECT</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12"><button className="btn btn-primary btn-lg">Submit Form</button></div>
              </div>
            </form>
          </div>
        </Container>
      </LoadingOverlay>
    )
  }
}