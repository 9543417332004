import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Home } from './components/Home';
import { Face2Face } from './components/Face2Face';
import { OUU } from './components/OUU';
import { SynergyPeople } from './components/SynergyPeople';
import { CandidateUpdateRequest } from './components/CandidateUpdateRequest';
import { Success } from './components/Success';

import './custom.css'
import TRC_PAYG from './components/TRC/PAYG';
import TRC_PtyLtd from './components/TRC/PtyLtd';
import TSC_PAYG from './components/TSC/PAYG';
import TSC_PtyLtd from './components/TSC/PtyLtd';
import TORC_PAYG from './components/TORC/PAYG';
import TORC_PtyLtd from './components/TORC/PtyLtd';

export default class App extends Component {
  static displayName = App.name;
  
  render () {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/face2face/RegistrationForm' component={Face2Face} />
        <Route path='/ouu/RegistrationForm' component={OUU} />
        <Route path='/SynergyPeople/RegistrationForm' component={SynergyPeople} />
        <Route path='/UpdateCandidate/:token' component={CandidateUpdateRequest} />
        <Route path='/TRC/PAYG/:token' component={TRC_PAYG} />
        <Route path='/TRC/PtyLtd/:token' component={TRC_PtyLtd} />
        <Route path='/TSC/PAYG/:token' component={TSC_PAYG} />
        <Route path='/TSC/PtyLtd/:token' component={TSC_PtyLtd} />
        <Route path='/TORC/PAYG/:token' component={TORC_PAYG} />
        <Route path='/TORC/PtyLtd/:token' component={TORC_PtyLtd} />
        
        <Route path='/success/:form' component={Success} />
      </Switch>
    );
  }
}
