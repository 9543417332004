import React, { Component } from 'react';
import './Face2Face.css';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Alert, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class Face2Face extends Component {
  static displayName = Face2Face.name;

  constructor(props) {
    super(props);
    document.title = "Face2Face Registration Form";
    this.state = {
        citizenship: 'Australian Citizen',
        securityClearance: 'None',
        howDidYouHearAboutUs: 'Google',
        divisionID: 'BusinessSupport',
        isOverlayActive: false,
        isRedirect: false,
        errorMessage: ''
    };
    this.resumeFileInput = React.createRef();
    this.photoIDFileInput = React.createRef();
  }

  handleChanges = (event) => {
    if (this.state.isOverlayActive === true) return;
    let value = event.target.type === 'number' ? event.target.valueAsNumber : event.target.value;
    if (event.target.type === 'file') {
      if (event.target.id === 'resumeFile') {
        value = this.resumeFileInput.current.files[0].name;
      }
      else if (event.target.id === 'photoIdFile') {
        value = this.photoIDFileInput.current.files[0].name;
      }
    }
    this.setState({
      [event.target.name]: value
    });
  }

  handleSubmit = async (event) => {
    if (this.state.isOverlayActive === true) return;
    event.preventDefault();
    this.setState({'isOverlayActive': true});

    const uploadData = new FormData();
    uploadData.append('email', this.state.email);
    uploadData.append('resume', this.resumeFileInput.current.files[0]);

    let files = [this.resumeFileInput.current.files[0]];
    if (this.photoIDFileInput.current.files[0]) {
      uploadData.append('photoId', this.photoIDFileInput.current.files[0]);
      files.push(this.photoIDFileInput.current.files[0]);
    }
    uploadData.append('files', files);

    try {
      let uploadResult = await fetch('api/Face2Face/uploadFiles', { method: 'POST', body: uploadData });
      if(!uploadResult.ok) {
        this.setState({'errorMessage': 'error'});
      }
      console.log('FILES UPLOADED');

      await fetch('api/Face2Face', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state) });
      console.log('RESPONSES SUBMITTED');

      this.setState({'isOverlayActive': false});
      this.setState({'isRedirect': true});

    } catch (error) {
      console.log(error);
      //DISPLAY ERROR
      this.setState({'isOverlayActive': false});
    }
  }

  handleClose() {   
    this.setState({'errorMessage': ''});
  };
  

  componentDidMount() {

  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to='/success/f2f'/>
    }
    return (
      <LoadingOverlay active={this.state.isOverlayActive} spinner>
        <Container>
          <div>
          {this.state.errorMessage !== '' && <Snackbar open={true} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert severity="error" variant="filled"  action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={this.handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }> Error occured please contact <a style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'white' }} href="mailto:support@recruitwizard.com">Support</a>.</Alert>
            </Snackbar>}
            <div className="form-description">Fields marked with an <span className="required-symbol">*</span> are required</div>
            <form onSubmit={this.handleSubmit}>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>First name<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" name="firstName" required onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Last name<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" name="lastName" required onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Phone<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" name="phone" required onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Email<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="email" name="email" required onChange={this.handleChanges} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Current location<span className="required-symbol">*</span></label></div>
                <div><p>E.g. Canberra, Sydney, Wollongong, Geelong</p></div>
                <div className="input-container"><input type="text" name="currentLocation" required onChange={this.handleChanges} /></div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>If applicable, please list locations you are willing to move for work.</label></div>
                <div className="input-container"><textarea name="locationsToMove" onChange={this.handleChanges}></textarea></div>
              </div>
              <div> {/*Row*/}
                <div>
                  <div className="form-title-container"><label>Availability <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Immediate" name="availability" required onChange={this.handleChanges} />Immediate</label></div>
                    <div><label><input type="radio" value="Available date" name="availability" /* checked={this.state.availability === 'Available date'} */ onChange={this.handleChanges} />Available date</label></div>
                    <div><label><input type="radio" value="Notice period" name="availability" /* checked={this.state.availability === 'Notice period'} */ onChange={this.handleChanges} />Notice period</label></div>
                  </div>
                </div>
              </div>
              {this.state.availability === "Available date" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>Date<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="date" name="availableDate" required={this.state.availability === "Available date"} onChange={this.handleChanges} /></div>
                </div>
              }
              {this.state.availability === "Notice period" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>Notice period <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="1week" name="noticePeriod" required={this.state.availability === "Notice period"} onChange={this.handleChanges} />1 week notice</label></div>
                    <div><label><input type="radio" value="2week" name="noticePeriod" onChange={this.handleChanges} />2 weeks' notice</label></div>
                    <div><label><input type="radio" value="3week" name="noticePeriod" onChange={this.handleChanges} />3 weeks' notice</label></div>
                    <div><label><input type="radio" value="4week" name="noticePeriod" onChange={this.handleChanges} />4 weeks' notice</label></div>
                  </div>
                </div>
              }
              <div> {/*Row*/}
                <div className="form-title-container"><label>Citizenship <span className="required-symbol">*</span></label></div>
                <div className="input-container">
                  <select name="citizenship" required onChange={this.handleChanges}>
                    <option value="Australian Citizen">Australian Citizen</option>
                    <option value="Permanent Resident">Permanent Resident</option>
                    <option value="Working Visa">Working Visa</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              {this.state.citizenship === "Other" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>If other, please specify: <span className="required-symbol">*</span></label></div>
                  <div className="input-container"><textarea name="citizenshipOther" required={this.state.citizenship === 'Other'} onChange={this.handleChanges}></textarea></div>
                </div>
              }
              <hr></hr>
              <div> {/*Row*/}
                <div>
                  <div className="form-title-container"><label>Do you identify as Aboriginal or Torres Strait Islander?</label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Yes" name="isAboriginal" onChange={this.handleChanges} />Yes</label></div>
                    <div><label><input type="radio" value="No" name="isAboriginal" onChange={this.handleChanges} />No</label></div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Do you hold a current Government Security Clearance? <span className="required-symbol">*</span></label></div>
                <div className="input-container">
                  <select name="securityClearance" required onChange={this.handleChanges}>
                    <option value="None">None</option>
                    <option value="Baseline">Baseline</option>
                    <option value="NV1">NV1</option>
                    <option value="NV2">NV2</option>
                    <option value="PV">PV</option>
                  </select>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Do you hold a current (6 months or less) police check? <span className="required-symbol">*</span></label></div>
                <div><p>We recommend you undertake a police check, as many organisations require this to on-board employees and it can assist you to start a new job sooner. You can undertake a police check through: <a href="https://www.crimcheck.org.au/">https://www.crimcheck.org.au/</a></p></div>
                <div className="input-container">
                  <div><label><input type="radio" value="Yes" name="hasPoliceCheck" required onChange={this.handleChanges} />Yes</label></div>
                  <div><label><input type="radio" value="No" name="hasPoliceCheck" onChange={this.handleChanges} />No</label></div>
                </div>
              </div>
              <hr></hr>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Do you have a preference for permanent or contract roles? <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Either" name="permanentOrContractRolePreference" required onChange={this.handleChanges} />Either</label></div>
                    <div><label><input type="radio" value="Contract" name="permanentOrContractRolePreference" onChange={this.handleChanges} />Contract</label></div>
                    <div><label><input type="radio" value="Permanent" name="permanentOrContractRolePreference" onChange={this.handleChanges} />Permanent</label></div>
                  </div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Do you have a preference for Government or private sector roles? <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Either" name="govermentOrPrivateRolePreference" required onChange={this.handleChanges} />Either</label></div>
                    <div><label><input type="radio" value="Government" name="govermentOrPrivateRolePreference" onChange={this.handleChanges} />Government</label></div>
                    <div><label><input type="radio" value="Private sector" name="govermentOrPrivateRolePreference" onChange={this.handleChanges} />Private sector</label></div>
                  </div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Please let us know if you are looking for a specific role.</label></div>
                <div className="input-container"><textarea name="specificRole" onChange={this.handleChanges}></textarea></div>
              </div>
              <div> {/*Row*/}
                  <div className="form-title-container"><label>Are you looking for Business Support or ICT roles?<span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                      <select name="divisionID" required onChange={this.handleChanges}>
                      <option value="BusinessSupport">Business Support</option>
                      <option value="ICT">ICT</option>
                      <option value="Either">Either</option>
                      </select>
                  </div>
              </div>
              <hr></hr>
              <div> {/*Row*/}
                <p>Please provide your preferred hourly rate (excluding Superannuation):</p>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Minimum $</label></div>
                  <div className="input-container"><input type="number" name="minHourlyRate" onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Maximum $</label></div>
                  <div className="input-container"><input type="number" name="maxHourlyRate" onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <p>And/or, please provide your preferred salary (excluding Superannuation):</p>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Minimum $</label></div>
                  <div className="input-container"><input type="number" name="minSalary" onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Maximum $</label></div>
                  <div className="input-container"><input type="number" name="maxSalary" onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Updated resume <span className="required-symbol">*</span></label></div>
                <div><p>Please provide an updated resume (preferably in Word format)</p></div>
                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                <div><input type="file" name="resumeFile" id="resumeFile" className="input-container" required ref={this.resumeFileInput} onChange={this.handleChanges} /></div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Photo ID</label></div>
                <div><p>Please provide a copy of one of these types of photo ID - Driver's licence or Passport</p></div>
                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                <div><input type="file" name="photoIdFile" id="photoIdFile" className="input-container" ref={this.photoIDFileInput} onChange={this.handleChanges} /></div>
              </div>
              <hr></hr>
              <div> {/*Row*/}
                <div className="form-title-container"><label>How did you hear about us? <span className="required-symbol">*</span></label></div>
                <div className="input-container">
                  <select name="howDidYouHearAboutUs" required onChange={this.handleChanges}>
                    <option value="Google">Google</option>
                    <option value="Seek">Seek</option>
                    <option value="CareerOne">CareerOne</option>
                    <option value="Word of Mouth">Word of Mouth</option>
                    <option value="Facebook">Facebook</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Advertisement">Advertisement</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              {this.state.howDidYouHearAboutUs === "Other" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>Please specify</label></div>
                  <div className="input-container"><input type="text" name="howDidYouHearAboutUsOther" onChange={this.handleChanges} /></div>
                </div>
              }
              <div> {/*Row*/}
                <div className="form-title-container"><label>Message (optional)</label></div>
                <div className="input-container"><textarea name="message" onChange={this.handleChanges}></textarea></div>
              </div>
              <div><input type="submit" value="Submit"></input></div>
            </form>
          </div>
        </Container>
      </LoadingOverlay>
    )
  }
}