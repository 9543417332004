import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <Container>
        <div>
              <h1>Recruit Wizard Custom Forms</h1>
              <p>Contact <a href="mailto:support@recruitwizard.com">support@recruitwizard.com</a> for more info</p>
        </div>
      </Container>
    );
  }
}