import React from 'react';
import { Container } from 'reactstrap';

export class Success extends React.Component {
  constructor(props) {
    super(props);
    document.title = "Success!";
    this.state = {form: ''};
  }
  async componentDidMount() {
    try {
      let form = this.props.match.params.form;
      console.log(form, this.props.match.params);
      this.setState({form: form});
    }
    catch (error) {
      console.log(error);
    } 
  }

  f2fSuccessMessage() {
    return (
      <span>
        <p>
            Thank you for registering with face2face Recruitment, one of our team will be in contact soon.
          </p>
          <p>
            Subscribe to receive our <a href="https://mailchi.mp/ed3da6e82c51/f2f-new-job-alerts">Latest Job Alerts</a>, and also keep an eye on our <a href="https://www.facebook.com/f2frecruitment/">Facebook</a> and <a href="https://www.linkedin.com/company/face2face-recruitment/">LinkedIn</a> pages for our latest news and articles.
          </p>
          <p>
            In the meantime to assist you with developing a great resume and invaluable interview tips, our Managing Director’s Amazon #1 Best Seller is now available: <a href="https://www.amazon.com.au/Resume-Success-Secrets-Write-That-ebook/dp/B017CU8I36/ref=zg_bs_2496754051_15">https://www.amazon.com.au/Resume-Success-Secrets-Write-That-ebook/dp/B017CU8I36/ref=zg_bs_2496754051_15</a>.
          </p>
      </span>
    )
  }

  render() {
    const {form} = this.state;
    
    return (
      <Container>
        <div>
          <h1 className='text-center text-success'>Data submitted successfully</h1>
          <br/>
          {(form === 'candidate-update') && 
            <p style={{textAlign: 'center'}}>{'Thanks for updating your details, we will be in touch with any opportunities that match your experience & skills'}</p>
          }
          {
            form === 'f2f' &&
            this.f2fSuccessMessage()
          }
          
        </div>
      </Container>
    );
  }
}

export default Success;