import React, { Component } from 'react';
import './CandidateUpdateRequest.css';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Alert, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class CandidateUpdateRequest extends Component {
  static displayName = CandidateUpdateRequest.name;

  constructor(props) {
    super(props);
    this.state = {
      isOverlayActive: false,
      isRedirect: false,
      errorMessage: ''
    };
    this.resumeFileInput = React.createRef();
    this.photoIDFileInput = React.createRef();
  }

  handleClose = () => {
    this.setState({'errorMessage': ''});
  };

  handleChanges = (event) => {
    if (this.state.isOverlayActive === true) return;
    let value = event.target.type === 'number' ? event.target.valueAsNumber : event.target.value;
    if (event.target.type === 'file') {
      if (event.target.id === 'resumeFile') {
        value = this.resumeFileInput.current.files[0].name;
      }
      else if (event.target.id === 'photoIdFile') {
        value = this.photoIDFileInput.current.files[0].name;
      }
    }
    this.setState({
      [event.target.name]: value
    });
  }

  handleSubmit = async (event) => {
    if (this.state.isOverlayActive === true) return;
    event.preventDefault();
    this.setState({ 'isOverlayActive': true });

    const uploadData = new FormData();
    uploadData.append('email', this.state.email);
    uploadData.append('resume', this.resumeFileInput.current.files[0]);

    let files = [this.resumeFileInput.current.files[0]];
    if (this.photoIDFileInput.current.files[0]) {
      uploadData.append('photoId', this.photoIDFileInput.current.files[0]);
      files.push(this.photoIDFileInput.current.files[0]);
    }
    uploadData.append('files', files);

    try {
      let token = this.props.match.params.token;

      console.log(this.state);

      let uploadResponse = await fetch(`api/UpdateCandidate/uploadFiles/${token}`, { method: 'POST', body: uploadData });
      if (!uploadResponse.ok) {
        this.setState({'errorMessage': 'error'});
      }
      console.log('FILES UPLOADED');

      await fetch(`api/UpdateCandidate/${token}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state) });
      console.log('RESPONSES SUBMITTED');

      this.setState({ 'isOverlayActive': false });
      this.setState({'isRedirect': true});

    } catch (error) {
      console.log(error);
      //DISPLAY ERROR
      this.setState({ 'isOverlayActive': false });
    }
  }

  async componentDidMount() {
    this.setState({ 'isOverlayActive': true });
    try {
      let token = this.props.match.params.token;
      let response = await fetch(`api/UpdateCandidate/${token}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
      if (response.ok) {
        let candidate = await response.json();
        console.log(candidate);
        this.setState({ 'firstName': candidate.firstName });
        this.setState({ 'lastName': candidate.lastName });
        this.setState({ 'mobile': candidate.mobile });
        this.setState({ 'email1': candidate.email1 });
        this.setState({ 'email2': candidate.email2 });
        this.setState({ 'address1': candidate.address1 });
        this.setState({ 'suburb': candidate.suburb });
        this.setState({ 'state': candidate.state });
        this.setState({ 'postcode': candidate.postcode });
        this.setState({ 'countryCode': candidate.countryCode });
        this.setState({ 'linkedinUrl': candidate.LinkedInUrl });
        this.setState({ 'twitterUrl': candidate.twitterUrl });
        this.setState({ 'facebookUrl': candidate.facebookUrl });
        this.setState({ 'preferredContractRate': candidate.preferredContractRate });
        this.setState({ 'preferredSalary': candidate.preferredSalary });
        this.setState({ 'jobSeekingStatusID': candidate.jobSeekingStatusID });
        this.setState({ 'customerID': candidate.customerID });
      }
      else {
        let msg = await response.text();
        if (!msg.startsWith('{') && !msg.startsWith('[')) this.setState({ 'errorMessage': msg });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ 'isOverlayActive': false });
  }



  render() {
    if (this.state.isRedirect) {
      return <Redirect to='/success/candidate-update' />
    }

    const errorMessage = this.state.errorMessage;
    return (
      <LoadingOverlay active={this.state.isOverlayActive} spinner>
        <Container>
          {errorMessage &&
              <Snackbar open={true} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert
                  severity="error"
                  variant="filled"
                  action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={this.handleClose}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                    {errorMessage}
                </Alert>
              </Snackbar>
          }
          <div>
            <div><img src={"https://cdn.recruitwizard.com/images/customer_logos/" + this.state.customerID + ".png"} alt="logo" /></div>
            <form onSubmit={this.handleSubmit}>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>First name<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.firstName || ''} name="firstName" required onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Last name<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.lastName || ''} name="lastName" required onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Email 1<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="email" value={this.state.email1 || ''} name="email1" required onChange={this.handleChanges} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Email 2</label></div>
                  <div className="input-container"><input type="email" value={this.state.email2 || ''} name="email2" onChange={this.handleChanges} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Phone<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.mobile || ''} name="mobile" required onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>LinkedIn</label></div>
                  <div className="input-container"><input type="text" value={this.state.linkedinUrl || ''} name="linkedInUrl" onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Facebook</label></div>
                  <div className="input-container"><input type="text" value={this.state.facebookUrl || ''} name="facebookUrl" onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Twitter</label></div>
                  <div className="input-container"><input type="text" value={this.state.twitterUrl || ''} name="twitterUrl" onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Address</label></div>
                <div className="input-container"><input type="text" value={this.state.address1 || ''} name="address1" required onChange={this.handleChanges} /></div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Suburb<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.suburb || ''} name="suburb" required onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>State<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.state || ''} name="state" required onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Country<span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <select name="countryCode" value={this.state.countryCode || '0'} required onChange={this.handleChanges}>
                      <option value="0">-- Select --</option>
                      <option value='AF'>Afghanistan</option>
                      <option value='AX'>Aland Islands</option>
                      <option value='AL'>Albania</option>
                      <option value='DZ'>Algeria</option>
                      <option value='AS'>American Samoa</option>
                      <option value='AD'>Andorra</option>
                      <option value='AO'>Angola</option>
                      <option value='AI'>Anguilla</option>
                      <option value='AQ'>Antarctica</option>
                      <option value='AG'>Antigua And Barbuda</option>
                      <option value='AR'>Argentina</option>
                      <option value='AM'>Armenia</option>
                      <option value='AW'>Aruba</option>
                      <option value='AU'>Australia</option>
                      <option value='AT'>Austria</option>
                      <option value='AZ'>Azerbaijan</option>
                      <option value='BS'>Bahamas</option>
                      <option value='BH'>Bahrain</option>
                      <option value='BD'>Bangladesh</option>
                      <option value='BB'>Barbados</option>
                      <option value='BY'>Belarus</option>
                      <option value='BE'>Belgium</option>
                      <option value='BZ'>Belize</option>
                      <option value='BJ'>Benin</option>
                      <option value='BM'>Bermuda</option>
                      <option value='BT'>Bhutan</option>
                      <option value='BO'>Bolivia</option>
                      <option value='BA'>Bosnia and Herzegovina</option>
                      <option value='BW'>Botswana</option>
                      <option value='BV'>Bouvet Island</option>
                      <option value='BR'>Brazil</option>
                      <option value='IO'>British Indian Ocean Territory</option>
                      <option value='VG'>British Virgin Islands</option>
                      <option value='BN'>Brunei Darussalam</option>
                      <option value='BG'>Bulgaria</option>
                      <option value='BF'>Burkina Faso</option>
                      <option value='BI'>Burundi</option>
                      <option value='KH'>Cambodia</option>
                      <option value='CM'>Cameroon</option>
                      <option value='CA'>Canada</option>
                      <option value='CV'>Cape Verde</option>
                      <option value='KY'>Cayman Islands</option>
                      <option value='CF'>Central African Republic</option>
                      <option value='TD'>Chad</option>
                      <option value='CL'>Chile</option>
                      <option value='CN'>China</option>
                      <option value='CX'>Christmas Island</option>
                      <option value='CC'>Cocos (Keeling) Islands</option>
                      <option value='CO'>Colombia</option>
                      <option value='KM'>Comoros</option>
                      <option value='CD'>Congo, The Democratic Republic of</option>
                      <option value='CG'>Congo, The Peoples Republic of</option>
                      <option value='CK'>Cook Islands</option>
                      <option value='CR'>Costa Rica</option>
                      <option value='HR'>Croatia</option>
                      <option value='CU'>Cuba</option>
                      <option value='CY'>Cyprus</option>
                      <option value='CZ'>Czech Republic</option>
                      <option value='DK'>Denmark</option>
                      <option value='DJ'>Djibouti</option>
                      <option value='DM'>Dominica</option>
                      <option value='DO'>Dominican Republic</option>
                      <option value='TL'>East Timor</option>
                      <option value='EC'>Ecuador</option>
                      <option value='EG'>Egypt</option>
                      <option value='SV'>El Salvador</option>
                      <option value='GQ'>Equatorial Guinea</option>
                      <option value='ER'>Eritrea</option>
                      <option value='EE'>Estonia</option>
                      <option value='ET'>Ethiopia</option>
                      <option value='FK'>Falkland Islands (Malvinas)</option>
                      <option value='FO'>Faroe Islands</option>
                      <option value='FJ'>Fiji</option>
                      <option value='FI'>Finland</option>
                      <option value='FR'>France</option>
                      <option value='GF'>French Guiana</option>
                      <option value='PF'>French Polynesia</option>
                      <option value='TF'>French Southern Territories</option>
                      <option value='GA'>Gabon</option>
                      <option value='GM'>Gambia</option>
                      <option value='GE'>Georgia</option>
                      <option value='DE'>Germany</option>
                      <option value='GH'>Ghana</option>
                      <option value='GI'>Gibraltar</option>
                      <option value='GR'>Greece</option>
                      <option value='GL'>Greenland</option>
                      <option value='GD'>Grenada</option>
                      <option value='GP'>Guadeloupe</option>
                      <option value='GU'>Guam</option>
                      <option value='GT'>Guatemala</option>
                      <option value='GG'>Guernsey</option>
                      <option value='GN'>Guinea</option>
                      <option value='GW'>Guinea-Bissau</option>
                      <option value='GY'>Guyana</option>
                      <option value='HT'>Haiti</option>
                      <option value='HM'>Heard and McDonald Islands</option>
                      <option value='HN'>Honduras</option>
                      <option value='HK'>Hong Kong</option>
                      <option value='HU'>Hungary</option>
                      <option value='IS'>Iceland</option>
                      <option value='IN'>India</option>
                      <option value='ID'>Indonesia</option>
                      <option value='IR'>Iran</option>
                      <option value='IQ'>Iraq</option>
                      <option value='IE'>Ireland (eire)</option>
                      <option value='IM'>Isle Of Man</option>
                      <option value='IL'>Israel</option>
                      <option value='IT'>Italy</option>
                      <option value='CI'>Ivory Coast</option>
                      <option value='JM'>Jamaica</option>
                      <option value='JP'>Japan</option>
                      <option value='JE'>Jersey</option>
                      <option value='JO'>Jordan</option>
                      <option value='KZ'>Kazakhstan</option>
                      <option value='KE'>Kenya</option>
                      <option value='KI'>Kiribati</option>
                      <option value='KP'>Korea, North</option>
                      <option value='KR'>Korea, South</option>
                      <option value='KW'>Kuwait</option>
                      <option value='KG'>Kyrgyzstan</option>
                      <option value='LA'>Lao People's Democratic Republic</option>
                      <option value='LV'>Latvia</option>
                      <option value='LB'>Lebanon</option>
                      <option value='LS'>Lesotho</option>
                      <option value='LR'>Liberia</option>
                      <option value='LY'>Libyan Arab Jamahiriya</option>
                      <option value='LI'>Liechtenstein</option>
                      <option value='LT'>Lithuania</option>
                      <option value='LU'>Luxembourg</option>
                      <option value='MO'>Macau</option>
                      <option value='MK'>Macedonia</option>
                      <option value='MG'>Madagascar</option>
                      <option value='MW'>Malawi</option>
                      <option value='MY'>Malaysia</option>
                      <option value='MV'>Maldives</option>
                      <option value='ML'>Mali</option>
                      <option value='MT'>Malta</option>
                      <option value='MH'>Marshall Islands</option>
                      <option value='MQ'>Martinique</option>
                      <option value='MR'>Mauritania</option>
                      <option value='MU'>Mauritius</option>
                      <option value='YT'>Mayotte</option>
                      <option value='MX'>Mexico</option>
                      <option value='FM'>Micronesia, Federated States of</option>
                      <option value='MD'>Moldova</option>
                      <option value='MC'>Monaco</option>
                      <option value='MN'>Mongolia</option>
                      <option value='ME'>Montenegro</option>
                      <option value='MS'>Montserrat</option>
                      <option value='MA'>Morocco</option>
                      <option value='MZ'>Mozambique</option>
                      <option value='MM'>Myanmar</option>
                      <option value='NA'>Namibia</option>
                      <option value='NR'>Nauru</option>
                      <option value='NP'>Nepal</option>
                      <option value='NL'>Netherlands</option>
                      <option value='AN'>Netherlands Antilles</option>
                      <option value='NC'>New Caledonia</option>
                      <option value='NZ'>New Zealand</option>
                      <option value='NI'>Nicaragua</option>
                      <option value='NE'>Niger</option>
                      <option value='NG'>Nigeria</option>
                      <option value='NU'>Niue</option>
                      <option value='NF'>Norfolk Island</option>
                      <option value='MP'>Northern Mariana Islands</option>
                      <option value='NO'>Norway</option>
                      <option value='OM'>Oman</option>
                      <option value='PK'>Pakistan</option>
                      <option value='PW'>Palau</option>
                      <option value='PS'>Palestinian Territory, Occupied</option>
                      <option value='PA'>Panama</option>
                      <option value='PG'>Papua New Guinea</option>
                      <option value='PY'>Paraguay</option>
                      <option value='PE'>Peru</option>
                      <option value='PH'>Philippines</option>
                      <option value='PN'>Pitcairn</option>
                      <option value='PL'>Poland</option>
                      <option value='PT'>Portugal</option>
                      <option value='PR'>Puerto Rico</option>
                      <option value='QA'>Qatar</option>
                      <option value='RE'>Reunion</option>
                      <option value='RO'>Romania</option>
                      <option value='RU'>Russia</option>
                      <option value='RW'>Rwanda</option>
                      <option value='KN'>Saint Kitts and Nevis</option>
                      <option value='LC'>Saint Lucia</option>
                      <option value='VC'>Saint Vincent and The Grenadines</option>
                      <option value='WS'>Samoa</option>
                      <option value='SM'>San Marino</option>
                      <option value='ST'>Sao Tome And Principe</option>
                      <option value='SA'>Saudi Arabia</option>
                      <option value='SN'>Senegal</option>
                      <option value='RS'>Serbia</option>
                      <option value='SC'>Seychelles</option>
                      <option value='SL'>Sierra Leone</option>
                      <option value='SG'>Singapore</option>
                      <option value='SK'>Slovakia</option>
                      <option value='SI'>Slovenia</option>
                      <option value='SB'>Solomon Islands</option>
                      <option value='SO'>Somalia</option>
                      <option value='ZA'>South Africa</option>
                      <option value='GS'>South Georgia and The South Sandwich Islands</option>
                      <option value='ES'>Spain</option>
                      <option value='LK'>Sri Lanka</option>
                      <option value='SH'>St. Helena</option>
                      <option value='PM'>St. Pierre and Miquelon</option>
                      <option value='SD'>Sudan</option>
                      <option value='SR'>Suriname</option>
                      <option value='SJ'>Svalbard and Jan Mayen Islands</option>
                      <option value='SZ'>Swaziland</option>
                      <option value='SE'>Sweden</option>
                      <option value='CH'>Switzerland</option>
                      <option value='SY'>Syrian Arab Republic</option>
                      <option value='TW'>Taiwan</option>
                      <option value='TJ'>Tajikistan</option>
                      <option value='TZ'>Tanzania</option>
                      <option value='TH'>Thailand</option>
                      <option value='TG'>Togo</option>
                      <option value='TK'>Tokelau</option>
                      <option value='TO'>Tonga</option>
                      <option value='TT'>Trinidad & Tobago</option>
                      <option value='TN'>Tunisia</option>
                      <option value='TR'>Turkey</option>
                      <option value='TM'>Turkmenistan</option>
                      <option value='TC'>Turks and Caicos Islands</option>
                      <option value='TV'>Tuvalu</option>
                      <option value='UG'>Uganda</option>
                      <option value='UA'>Ukraine</option>
                      <option value='AE'>United Arab Emirates</option>
                      <option value='GB'>United Kingdom</option>
                      <option value='UM'>United States Minor Outlying Islands</option>
                      <option value='UY'>Uruguay</option>
                      <option value='US'>USA</option>
                      <option value='UZ'>Uzbekistan</option>
                      <option value='VU'>Vanuatu</option>
                      <option value='VA'>Vatican City State (Holy See)</option>
                      <option value='VE'>Venezuela</option>
                      <option value='VN'>Vietnam</option>
                      <option value='VI'>Virgin Islands (U.S.)</option>
                      <option value='WF'>Wallis And Futuna Islands</option>
                      <option value='EH'>Western Sahara</option>
                      <option value='YE'>Yemen</option>
                      <option value='ZM'>Zambia</option>
                      <option value='ZW'>Zimbabwe</option>
                    </select>
                  </div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Postcode<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="text" value={this.state.postcode || ''} name="postcode" required onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Job Seeking Status <span className="required-symbol">*</span></label></div>
                <div className="input-container">
                  <select name="jobSeekingStatusID" value={this.state.jobSeekingStatusID || '0'} required onChange={this.handleChanges}>
                    <option value="0">-- Select --</option>
                    <option value="1">Seriously Looking</option>
                    <option value="2">Open to Opportunities</option>
                    <option value="3">Not Currently looking</option>
                  </select>
                </div>
              </div>
              <div> {/*Row*/}
                <div>
                  <div className="form-title-container"><label>Availability <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Immediate" name="availability" required onChange={this.handleChanges} />Immediate</label></div>
                    <div><label><input type="radio" value="Available date" name="availability" /* checked={this.state.availability === 'Available date'} */ onChange={this.handleChanges} />Available date</label></div>
                    <div><label><input type="radio" value="Notice period" name="availability" /* checked={this.state.availability === 'Notice period'} */ onChange={this.handleChanges} />Notice period</label></div>
                  </div>
                </div>
              </div>
              {this.state.availability === "Available date" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>Date<span className="required-symbol">*</span></label></div>
                  <div className="input-container"><input type="date" name="availableDate" required={this.state.availability === "Available date"} onChange={this.handleChanges} /></div>
                </div>
              }
              {this.state.availability === "Notice period" &&
                <div> {/*Row*/}
                  <div className="form-title-container"><label>Notice period <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="1week" name="noticePeriod" required={this.state.availability === "Notice period"} onChange={this.handleChanges} />1 week notice</label></div>
                    <div><label><input type="radio" value="2week" name="noticePeriod" onChange={this.handleChanges} />2 weeks' notice</label></div>
                    <div><label><input type="radio" value="3week" name="noticePeriod" onChange={this.handleChanges} />3 weeks' notice</label></div>
                    <div><label><input type="radio" value="4week" name="noticePeriod" onChange={this.handleChanges} />4 weeks' notice</label></div>
                  </div>
                </div>
              }
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Do you have a preference for permanent or contract roles? <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Either" name="permanentOrContractRolePreference" required onChange={this.handleChanges} />Either</label></div>
                    <div><label><input type="radio" value="Contract" name="permanentOrContractRolePreference" onChange={this.handleChanges} />Contract</label></div>
                    <div><label><input type="radio" value="Permanent" name="permanentOrContractRolePreference" onChange={this.handleChanges} />Permanent</label></div>
                  </div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Do you have a preference for Government or private sector roles? <span className="required-symbol">*</span></label></div>
                  <div className="input-container">
                    <div><label><input type="radio" value="Either" name="govermentOrPrivateRolePreference" required onChange={this.handleChanges} />Either</label></div>
                    <div><label><input type="radio" value="Government" name="govermentOrPrivateRolePreference" onChange={this.handleChanges} />Government</label></div>
                    <div><label><input type="radio" value="Private sector" name="govermentOrPrivateRolePreference" onChange={this.handleChanges} />Private sector</label></div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div> {/*Row*/}
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Preferred Hourly Rate</label></div>
                  <div className="input-container"><input type="number" value={this.state.preferredContractRate || ''} name="preferredContractRate" onChange={this.handleChanges} /></div>
                </div>
                <div className="half-column"> {/*Column*/}
                  <div className="form-title-container"><label>Preferred Salary</label></div>
                  <div className="input-container"><input type="number" value={this.state.preferredSalary || ''} name="preferredSalary" onChange={this.handleChanges} /></div>
                </div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Updated resume <span className="required-symbol">*</span></label></div>
                <div><p>Please provide an updated resume (preferably in Word format)</p></div>
                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                <div><input type="file" name="resumeFile" id="resumeFile" className="input-container" required ref={this.resumeFileInput} onChange={this.handleChanges} /></div>
              </div>
              <div> {/*Row*/}
                <div className="form-title-container"><label>Photo ID</label></div>
                <div><p>Please provide a copy of one of these types of photo ID - Driver's licence or Passport</p></div>
                {/* <div className="input-container"><input type="text"/></div> */} {/*CHANGE TO FILE SELECTOR*/}
                <div><input type="file" name="photoIdFile" id="photoIdFile" className="input-container" ref={this.photoIDFileInput} onChange={this.handleChanges} /></div>
              </div>
              <div><input type="submit" value="Submit"></input></div>
            </form>
          </div>
        </Container>
      </LoadingOverlay>
    )
  }
}